import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tags } from "../components/Tags";
import { Disqus } from "gatsby-plugin-disqus";
import ImageGallery from "react-image-gallery";
import { get } from "lodash";
// import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
// deckDeckGoHighlightElement();
const worthShowingActualOriginal = (filename) =>
  [".webp", ".gif", ".png", ".svg"].some((ext) => filename.includes(ext));
export default ({ data, location }) => {
  const post = data.markdownRemark;
  console.log("::post", post);
  console.log("::data", data);
  let images;
  if (
    get(post, "frontmatter.images[0].childImageSharp.original.src")
    // get(post, "frontmatter.images[0].childImageSharp.full.src") &&
    // get(post, "frontmatter.images[0].childImageSharp.thumbnail.src")
  ) {
    images = post.frontmatter.images.map((i) => ({
      original: i.childImageSharp.original.src,
      // original: worthShowingActualOriginal(i.childImageSharp.original.src)
      //   ? i.childImageSharp.original.src
      //   : i.childImageSharp.full.src,
      // thumbnail: i.childImageSharp.thumbnail.src,
      thumbnail: i.childImageSharp.original.src,
    }));
    console.log("::images", images);
  }
  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <style>{post.frontmatter.cheekyPageStyle}</style>
      <article className="post">
        {post.frontmatter.youtubeVideoId ? (
          <iframe
            width="1024"
            height="600"
            frameborder="0"
            src={`https://www.youtube.com/embed/${post.frontmatter.youtubeVideoId}?controls=0&showinfo=0&autoplay=1`}
            // autoplay isn't a thing anymore in chrome, without using API stuff
            // but leave here JIC
            allow="autoplay; encrypted-media"
          ></iframe>
        ) : null}
        {images ? (
          // (PROBS) DOESN'T HANDLE GIFS - E.G. REDDIT LIVE FEED
          <div className="gallery">
            <ImageGallery
              {...{
                items: images,
                autoPlay: false,
                showPlayButton: false,
                showThumbnails: images.length > 1,
              }}
            />
          </div>
        ) : null}
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <small>{post.frontmatter.date}</small>
          <Tags {...{ post }} />
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <Disqus
        config={{
          url: `${data.site.siteMetadata.siteUrl + location.pathname}`,
          identifier: post.id,
          title: post.frontmatter.title,
        }}
      />
    </Layout>
  );
};
export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        description
        tags
        youtubeVideoId
        cheekyPageStyle
        images {
          publicURL
          childImageSharp {
            original {
              src
            }
            # Stopping entirely from using resized images in posts because.. well, I want to but Netlify can't fucking cope with the slow build of it so I'll pay for it in slow page loads instead
            # full: fixed(width: 2048) {
            #   # ...GatsbyImageSharpResolutions_tracedSVG
            #   ...GatsbyImageSharpFixed
            #   # ...GatsbyImageSharpFixed_withWebp
            # }
            # thumbnail: fixed(width: 240) {
            #   src
            # }
            # resize(width: 500, height: 500) {
            #   src
            # }
            # name2: resolutions(width: 500, height: 500) {
            #   ...GatsbyImageSharpResolutions_tracedSVG
            # }
          }
        }
      }
    }
  }
`;
